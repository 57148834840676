import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import styles from '~/components/magic-modal/Components/magic-modal.module.scss';
import { useGlobalContext } from '~/global/Contexts/Global.context';

const StandardModalTitle = observer(() => {
	const {
		magicModal,
		magicModal: {
			model: {
				title,
				hasTitle,
			} = {},
		} = {},
	} = useGlobalContext();

	if (!magicModal) {
		return null;
	}
	return (
		<div className="MagicModal-title">
			{
				hasTitle &&
				<h1
					data-qa="auto-modal-title"
					id="standard-modal-title"
					className="tw-heading-4 md:tw-heading-3"
				>
					{title}
				</h1>
			}
		</div>
	);
});

export const StandardModal = observer((props) => {
	const { magicModal: propsMagicModal } = props;
	const { magicModal: contextMagicModal } = useGlobalContext();
	const magicModal = propsMagicModal || contextMagicModal;
	const { closeModal } = magicModal;
	const {
		accessibleTitle,
		anchorBottom,
		closeBtnWhiteBackground,
		closeButtonHandler,
		content,
		dropShadow,
		fullBleed,
		height,
		marginTop,
		maxWidth,
		reverseCloseButton,
		showCloseButton,
		showHeader,
		hasTitle,
		role = 'dialog',
		title,
		widthOutput,
	} = magicModal.model;

	const modalProps = {
		'aria-label': accessibleTitle || title || 'modal dialog',
		'className': cn('MagicModal', props.className, {
			'MagicModal--fullBleed': fullBleed,
			'tw-fixed tw-bottom-0 tw-left-1/2 tw--translate-x-1/2': anchorBottom,
			'MagicModalDropShadow': dropShadow,
		}),
		role,
		'aria-modal': true,
		'style': {
			width: widthOutput,
			maxWidth,
			marginTop,
			height,
		}
	};

	if (hasTitle) {
		modalProps['aria-labelledby'] = 'standard-modal-title';
	}

	return (
		<div key="mainModal" {...modalProps}>
			{
				(showHeader || showCloseButton) &&
				<div className="MagicModal-header">
					{
						showHeader &&
						<StandardModalTitle />
					}
					{
						(!showHeader && hasTitle) &&
						<h1 id="standard-modal-title" className="tw-sr-only">{magicModal?.model?.title}</h1>
					}
					{
						showCloseButton &&
						<div className={cn('MagicModal-closeBtnWrapper', {
							[styles['close-btn-white-bkg']]: closeBtnWhiteBackground,
						})}>
							<button
								className={cn({
									'MagicModal-closeBtn': !reverseCloseButton,
									'MagicModal-reverseCloseBtn': reverseCloseButton,
								})}
								data-qa="auto-modal-close-button"
								data-tr-link-event-track={false}
								data-ignore-blur-validation
								onMouseDown={(event) => {
									// Prevent form validation if the intent is to close.
									event.preventDefault();
									event.stopPropagation();
								}}
								onClick={() => {
									if (typeof closeButtonHandler === 'function') {
										closeButtonHandler(closeModal);
										return;
									}
									closeModal();
								}}
								title="Close modal window"
							>
								<div className="tw-sr-only">Close modal window</div>
							</button>
						</div>
					}
				</div>
			}
			{
				Boolean(content?.children) &&
				<div className="MagicModal-content" data-qa="magic-modal-content">{content.children}</div>
			}
		</div>
	);
});
