'use client';

import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { CSSTransition } from 'react-transition-group';

import { isNumber } from '~/util/isNumber';
import { isFunction } from '~/util/isFunction';

import { isOnServer } from '~/global/global.constants';
import { StandardModal } from '~/components/magic-modal/Components/StandardModal';
import { TrayModal } from '~/components/magic-modal/Components/TrayModal';
import { FocusLock } from '~/util/Components/FocusLock';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { LAUNCH_PLACEHOLDER_TEXT } from '~/util/launchUtils';

import trayStyles from '~/components/magic-modal/Components/magic-tray.module.scss';

export const MagicModal = observer((props: any) => {
	const { magicModal: propsMagicModal } = props;

	const { magicModal: contextMagicModal } = useGlobalContext();

	const magicModal = propsMagicModal || contextMagicModal;

	const {
		model,
		closeModal,
		model: {
			closeModalOnOverlayClick,
			containerClass,
			dataQa,
			id,
			isLoading,
			isOpen,
			isTray = false,
			trayAlignment = 'right',
			onOverlayClick,
			showOverlay,
			WrapperComponent,
			modalClassName,
			title,
			trLinkEventCompName = null,
			trLinkEventCompType = 'modal',
			trLinkEventUseCompNameH1 = null,
		},
	} = magicModal;

	const onKeyupHandler = (e: KeyboardEvent) => {
		if (e.keyCode !== 27) {
			return;
		}

		if (magicModal.model.closeModalOnEscKey) {
			closeModal();
		}
	};

	// will mount / will unmount
	useEffect(() => {
		document.querySelector('body')?.addEventListener?.('scroll-to-top-of-modal', magicModal.scrollToTop);
		if (!isOnServer) {
			document.addEventListener('keyup', onKeyupHandler);
		}

		return function cleanup() {
			document.removeEventListener('keyup', onKeyupHandler);
		};
	}, []);

	// will update / did update
	useEffect(() => {
		const { initialScrollTop, keepScrollPosition } = model;

		if (!keepScrollPosition && isOpen && isNumber(initialScrollTop)) {
			window.scrollTo(0, initialScrollTop);
		}

		if (keepScrollPosition) {
			model.scrollTop = window.scrollY;
		}

		if (isOpen && keepScrollPosition) {
			window.scrollTo(0, model.scrollTop);
		}
	});

	const wrapperDivRef = useRef(null);

	const isModal = !isTray;

	// TODO refactor to use global ID or a class name
	const containerID = isModal ? 'react-magic-modal' : trayStyles['react-magic-tray'];

	const defaultWrapper = isModal ? StandardModal : TrayModal;
	const Wrapper = WrapperComponent || defaultWrapper;

	const wrapperClass = isModal ? 'react-magic-modal-open' : 'react-magic-tray-open';

	const wrapperDivProps = {
		'className': cn(wrapperClass, id, Wrapper.WRAPPER_ID, modalClassName, {
			'left-aligned': trayAlignment === 'left',
			'right-aligned': trayAlignment === 'right',
		}),
		...(dataQa || id) && {
			'data-qa': dataQa || id,
		},
		'data-tr-link-event-comp-name': trLinkEventCompName || title || LAUNCH_PLACEHOLDER_TEXT,
		'data-tr-link-event-comp-type': trLinkEventCompType,
		'data-tr-link-event-use-comp-name-h1': trLinkEventUseCompNameH1,
	};

	return (
		<div id={containerID} className={containerClass}>
			<CSSTransition
				timeout={isModal ? 200 : 550}
				in={isOpen}
				classNames={isModal ? 'fade' : 'slide'}
				nodeRef={wrapperDivRef}
				mountOnEnter
				unmountOnExit
			>
				<div ref={wrapperDivRef} {...wrapperDivProps}>
					<FocusLock isLoading={isLoading}>
						<Wrapper {...props} />
					</FocusLock>
					{
						showOverlay &&
						<div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
							className="magic-modal-overlay"
							data-qa="magic-modal-overlay"
							key="magic-modal-overlay"
							tabIndex={-1}
							onClick={(event) => {
								if ('className' in event.target && event.target.className !== 'magic-modal-overlay') {
									return;
								}
								if (isFunction(onOverlayClick)) {
									onOverlayClick();
								} else if (closeModalOnOverlayClick) {
									closeModal();
								}
							}}
						/>
					}
				</div>
			</CSSTransition>
		</div>
	);
});
