import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import styles from '~/components/magic-modal/Components/magic-tray.module.scss';

const StandardModalTitle = observer(() => {
	const {
		magicModal,
		magicModal: {
			model: {
				title,
				hasTitle,
			} = {},
		} = {},
	} = useGlobalContext();

	if (!magicModal) {
		return null;
	}
	return (
		<div className="MagicModal-title">
			{
				hasTitle &&
				<h1 data-qa="auto-modal-title" id="standard-modal-title" className="tw-heading-4 md:tw-heading-3">
					{title}
				</h1>
			}
		</div>
	);
});

export const TrayModal = observer((props) => {
	const {
		magicModal,
	} = useGlobalContext();
	const {
		closeModal,
	} = magicModal;
	const {
		accessibleTitle,
		content,
		closeButtonHandler,
		hasTitle,
		isOpen,
		reverseCloseButton,
		showCloseButton,
		showHeader,
		title,
		widthOutput,
	} = magicModal.model;

	const scrollingParentRef = useRef();
	const scrollingChildRef = useRef();
	const [showShadow, setShowShadow] = useState(false);

	let ticking = false;

	const getShouldShowShadow = ({
		scrollingChildRef: scrollingChild = {},
		scrollingParentRef: scrollingParent = {},
	}) => {
		const {
			current: {
				offsetHeight: parentHeight,
				scrollTop,
			},
		} = scrollingParent;

		const {
			current: {
				scrollHeight: childHeight,
			},
		} = scrollingChild;

		const childIsTaller = childHeight > parentHeight;

		const maxScrollTop = childHeight - parentHeight;

		const shouldShowShadow = childIsTaller && (scrollTop === 0 || scrollTop < (maxScrollTop - 1));

		return shouldShowShadow;
	};

	function setShowShadowState() {
		const shouldShowShadow = getShouldShowShadow({
			scrollingChildRef,
			scrollingParentRef,
		});

		setShowShadow(shouldShowShadow);
	}

	function handleScroll() {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				ticking = false;
				setShowShadowState();
			});
			ticking = true;
		}
	}

	const modalProps = {
		'aria-label': accessibleTitle || title || 'modal dialog',
		'className': cn('MagicModal sm:tw-w-full', props.className, {
			[styles['tray-shadow']]: showShadow,
		}),
		'key': 'mainModal',
		'role': 'dialog',
		'aria-modal': true,
		'style': {
			width: `${widthOutput}`,
		}
	};

	if (hasTitle) {
		modalProps['aria-labelledby'] = 'standard-modal-title';
	}

	useEffect(() => {
		// wait for the slide-in/slide-out animation to complete
		setTimeout(() => {
			setShowShadowState();
		}, 350);
	}, [isOpen]);

	return (
		<div {...modalProps}
			ref={scrollingParentRef}
			onScroll={handleScroll}>
			<div className={styles['magic-modal-scroll-container']} ref={scrollingChildRef} >
				{
					(showHeader || showCloseButton) &&
					<div className="MagicModal-header">
						{
							showHeader &&
							<StandardModalTitle />
						}
						{
							(!showHeader && hasTitle) &&
							<h1 id="standard-modal-title" className="tw-sr-only">{magicModal?.model?.title}</h1>
						}
						{
							showCloseButton &&
							<div className="MagicModal-closeBtnWrapper">
								<button
									className={cn({
										'MagicModal-closeBtn': !reverseCloseButton,
										'MagicModal-reverseCloseBtn': reverseCloseButton,
									})}
									data-qa="auto-modal-close-button"
									data-tr-link-event-track={false}
									data-ignore-blur-validation
									onMouseDown={(event) => {
										// Prevent form validation if the intent is to close.
										event.preventDefault();
										event.stopPropagation();
									}}
									onClick={() => {
										if (typeof closeButtonHandler === 'function') {
											closeButtonHandler(closeModal);
											return;
										}
										closeModal();
									}}
									title="Close modal window"
								>
									<div className="tw-sr-only">Close modal window</div>
								</button>
							</div>
						}
					</div>
				}
				{
					Boolean(content?.children) &&
					<div className="MagicModal-content" data-qa="magic-modal-content">
						{content.children}
					</div>
				}
			</div>
		</div>
	);
});
