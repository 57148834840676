'use client';

import React, { ButtonHTMLAttributes, forwardRef, useEffect } from 'react';

import type { ForwardedRef } from 'react';

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
}

export const ButtonBase = forwardRef((props: IProps, ref: ForwardedRef<HTMLButtonElement>) => {
	const {
		...buttonProps
	} = props;

	const [hasBeenHydrated, setHasBeenHydrated] = React.useState(false);

	useEffect(() => {
		setHasBeenHydrated(true);
	}, []);

	return (
		<button
			{...buttonProps}
			data-is-hydrated={hasBeenHydrated}
			ref={ref}
		>
			{props.children}
		</button>
	);
});
