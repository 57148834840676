import React, { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { noop } from '~/util/noop';
import { isFocusable } from '~/util/isFocusable';
import { isOnServer } from '~/global/global.constants';

type Props = {
	children: React.ReactNode
	isLoading: boolean
}
export const isKeyboardEventType = (
	obj: KeyboardEvent | FocusEvent,
): obj is KeyboardEvent => {
	return 'keyCode' in obj;
};

// Get all visible focusable elements within the modal.
const getFocusableElements = (element: HTMLDivElement) => {
	if (!element) {
		return [];
	}
	const childElements = element.querySelectorAll<HTMLElement>(
		'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]'
	);

	const focusableElements: HTMLElement[] = [];
	[...childElements].forEach((childElement) => {
		if (isFocusable(childElement)) {
			focusableElements.push(childElement);
		}
	});

	return focusableElements;
};

export const FocusLock = observer((props: Props) => {
	const focusLockRef = useRef<HTMLDivElement>(null);
	const [originallyFocusedElement, setOriginallyFocusedElement] = useState<Element | null>(null);
	const focusEventHandler = (e: KeyboardEvent | FocusEvent) => {
		let index;
		let elem;

		if (!isKeyboardEventType(e)) {
			return;
		}
		// Everything but TAB key
		if (e.keyCode !== 9) {
			return;
		}
		if (!focusLockRef.current) {
			return;
		}
		const focusableElements = getFocusableElements(focusLockRef.current);
		const firstFocusedElement = focusableElements[0];
		const lastFocusedElement = focusableElements[focusableElements.length - 1];
		const currentlyFocusedElement = document.activeElement;

		if (e.shiftKey) {
			// Skip over the iframe if it is the last focusable element within the modal.
			// Not sure why we would skip this. Probably caused a bunch of issues typical of iframes.
			if (lastFocusedElement?.tagName === 'iframe') {
				index = focusableElements.length - 2;
				if (focusableElements.length <= 1) {
					index = 0;
				}
				elem = focusableElements[index];
				if (elem) {
					elem.focus();
				}
			} else if (currentlyFocusedElement === firstFocusedElement) {
				// We are at the first focusable element, loop around back to the last element.
				if (lastFocusedElement) {
					lastFocusedElement.focus();
				}
				e.preventDefault();
			}
		} else if (currentlyFocusedElement === lastFocusedElement) {
			// We are at the last element, loop around to the first element.
			elem = firstFocusedElement;
			if (elem) {
				elem.focus();
			}
			e.preventDefault();
		}
	};

	useEffect(() => {
		if (isOnServer || props.isLoading) {
			return noop;
		}
		const body = document.querySelector('body');

		if (!body) {
			return noop;
		}
		body.addEventListener('keydown', focusEventHandler);
		body.addEventListener('focus', focusEventHandler);

		const targetElement = focusLockRef.current ? getFocusableElements(focusLockRef.current) : [];

		setOriginallyFocusedElement(document.activeElement);

		if (targetElement.length) {
			targetElement[0].focus();
		}

		return function cleanup() {
			(originallyFocusedElement as HTMLElement)?.focus?.();
			document.querySelector('body')?.removeEventListener?.('keydown', focusEventHandler);
			document.querySelector('body')?.removeEventListener?.('focus', focusEventHandler);
		};
	}, [props]);

	return (
		<div className="focus-lock" ref={focusLockRef}>{props.children}</div>
	);
});
